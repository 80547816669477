import api from '~/services/api';
// import { Iten } from '../xml-types';
import { Iten, PromiseReturn, ValidateFornProps } from '../protocols';

// Validações capa

export async function isFornNotRegisteredOrInactive({
  cod_pessoa,
  flg_fornecedor,
  flg_inativo,
  des_uf,
  setStatus,
}: ValidateFornProps): Promise<PromiseReturn> {
  if (cod_pessoa === undefined || cod_pessoa === null || cod_pessoa === 0) {
    if (setStatus) {
      setStatus((prev) => [...prev, '[falha] - Pessoa não cadastrada.']);
    }
    return {
      message: 'Pessoa não cadastrada.',
      invalid: true,
    };
  }
  if (flg_fornecedor === null || flg_fornecedor === false) {
    if (setStatus) {
      setStatus((prev) => [
        ...prev,
        '[falha] - Pessoa não cadastrada como fornecedor.',
      ]);
    }
    return {
      message: 'Pessoa não cadastrada como fornecedor.',
      invalid: true,
    };
  }
  if (flg_inativo === null || flg_inativo === true) {
    if (setStatus) {
      setStatus((prev) => [...prev, '[falha] - Fornecedor inativo.']);
    }
    return {
      message: 'Fornecedor inativo.',
      invalid: true,
    };
  }
  if (des_uf === null || des_uf === undefined || des_uf.trim() === '') {
    return {
      message: 'Uf não cadastrada para o parceiro.',
      invalid: true,
    };
  }
  return {
    message: 'Fornecedor validado com sucesso.',
    invalid: false,
  };
}

export async function isNotaFiscalCanceled(
  cod_status: number,
): Promise<PromiseReturn> {
  if (cod_status === 101) {
    return {
      message: 'Nota Fiscal cancelada. Validação não permitida.',
      invalid: true,
    };
  }
  return {
    message: 'Status NF Ok.',
    invalid: false,
  };
}

export async function isPerfilNotSelectd(
  cod_perfil: number,
): Promise<PromiseReturn> {
  if (
    cod_perfil === undefined ||
    Number.isNaN(cod_perfil) ||
    cod_perfil === 0
  ) {
    return {
      message: 'Perfil deve ser selecionado.',
      invalid: true,
    };
  }
  return {
    message: 'Perfil validado com sucesso.',
    invalid: false,
  };
}

export async function isTipoStatusManifestoNotValid(
  tipo_status_manifesto: number,
  setStatus?: React.Dispatch<React.SetStateAction<string[]>>,
): Promise<PromiseReturn> {
  if (
    tipo_status_manifesto === undefined ||
    Number.isNaN(tipo_status_manifesto) ||
    tipo_status_manifesto === 0
  ) {
    if (setStatus) {
      setStatus((prev) => [
        ...prev,
        '[falha] - Não foi possivel verificar tipo_status_manifesto.',
      ]);
    }
    return {
      message: 'Não foi possivel verificar tipo_status_manifesto.',
      invalid: true,
    };
  }
  if (tipo_status_manifesto === 4) {
    if (setStatus) {
      setStatus((prev) => [
        ...prev,
        '[falha] - NFe manifestada como Operação “Não Realizada”. Validação não permitida.',
      ]);
    }
    return {
      message:
        'NFe manifestada como Operação “Não Realizada”. Validação não permitida.',
      invalid: true,
    };
  }
  if (tipo_status_manifesto === 3) {
    if (setStatus) {
      setStatus((prev) => [
        ...prev,
        '[falha] - NFe manifestada como Operação “Desconhecida”. Validação não permitida.',
      ]);
    }
    return {
      message:
        'NFe manifestada como Operação “Desconhecida”. Validação não permitida.',
      invalid: true,
    };
  }
  return {
    message: 'Tipo status manifesto validado com sucesso.',
    invalid: false,
  };
}

/**
 * Método verifica se a NFe já foi processada validando se o tipo_status é igual 3 ou 4.
 * tipo_status 3 => Processada
 * tipo_status 4 => Validada
 */
export async function isNFProcessede(
  tipo_status: number,
  setStatus?: React.Dispatch<React.SetStateAction<string[]>>,
): Promise<PromiseReturn> {
  const validStatuses = [3, 4];
  if (validStatuses.includes(tipo_status)) {
    if (setStatus) {
      setStatus((prev) => [...prev, '[Aviso] - Nota Fiscal já foi efetivada.']);
    }
    const des_tipo_status = tipo_status === 3 ? 'processada' : 'efetivada';
    return {
      title: `Nota Fiscal já foi ${des_tipo_status}.`,
      message: 'Deseja continuar mesmo assim?',
      invalid: true,
    };
  }
  return {
    message: 'NF não processada.',
    invalid: false,
  };
}

export async function isNFValidated(
  tipo_status: number,
  setStatus?: React.Dispatch<React.SetStateAction<string[]>>,
): Promise<PromiseReturn> {
  if (tipo_status >= 2) {
    if (setStatus) {
      setStatus((prev) => [
        ...prev,
        '[Aviso] - XML já foi confirmado/validado. As alterações já efetuadas serão descartadas.',
      ]);
    }
    return {
      title: `XML já foi confirmado/validado.
        As alterações já efetuadas serão descartadas.`,
      message: 'Deseja continuar mesmo assim?',
      invalid: true,
    };
  }
  return {
    message: 'NF não validada.',
    invalid: false,
  };
}

// Validações Itens
export async function formatCeanAndCeanTrib(item: Iten): Promise<string> {
  if (item.cEAN.length > 13) {
    if (item.cEANTrib !== '' && item.cEANTrib.length <= 13)
      return item.cEANTrib;
    return '';
  }
  if (item.cEAN === '' && item.cEANTrib !== '') {
    if (item.cEANTrib.length > 13) {
      if (item.cEANTrib.length === 14) return item.cEANTrib.substring(1, 14);
      return '';
    }
    return item.cEANTrib;
  }
  if (item.cEANTrib !== '' && item.cEANTrib.length === 13) return item.cEANTrib;
  return item.cEAN;
}

interface PluAndGtin {
  cod_produto: string;
  cod_gtin: string;
}
export async function getPluAndGtin(
  gtin: string,
  fornecedor: number,
  referencia: string,
  loja: number,
  sinalizador: boolean,
): Promise<PluAndGtin[]> {
  const { data } = await api.post('/buscaItensPorReferencia', {
    cod_fornecedor: fornecedor,
    des_referencia: referencia,
    cod_loja: loja,
    cod_gtin: gtin,
    sinalizador: sinalizador ? 0 : 1,
  });
  return data.data;
}
